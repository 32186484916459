import React, { useRef } from "react";

import AdvantagesView from "./view";

import { useAnim } from "./useAnim";

import { FLAGS, BENEFITS_DATA } from "./const";

const AdvantagesController = () => {
  const benefitsListRef = useRef(null);
  const serversRef = useRef(null);
  const speedRef = useRef(null);
  const speedValueRef = useRef(null);
  const speedCircleRef1 = useRef(null);
  const speedCircleRef2 = useRef(null);
  const flagRefs = useRef([]);

  useAnim({
    benefitsListRef,
    serversRef,
    speedRef,
    speedValueRef,
    speedCircleRef1,
    speedCircleRef2,
    flagRefs,
  });

  return (
    <AdvantagesView
      FLAGS={FLAGS}
      benefitsData={BENEFITS_DATA}
      // refs
      flagRefs={flagRefs}
      serversRef={serversRef}
      speedRef={speedRef}
      speedCircleRef1={speedCircleRef1}
      speedCircleRef2={speedCircleRef2}
      speedValueRef={speedValueRef}
      benefitsListRef={benefitsListRef}
    />
  );
};

export default AdvantagesController;
