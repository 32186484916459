import React from "react";

import { format } from "date-fns";

import { useStore } from "../../../store";

import BillingTabView from "./view";

const BillingTabController = () => {
  const { paymentHistory, getPaymentHistoryLoading: isLoading } = useStore();

  const formattedDate = (date) => format(new Date(date), "dd MMM yyyy, HH:mm");

  return (
    <BillingTabView
      isLoading={isLoading}
      paymentHistory={paymentHistory}
      formattedDate={formattedDate}
    />
  );
};

export default BillingTabController;
