import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import LoginPopup from "../ui/LoginPopup";
import ResetPassPopup from "../ui/ResetPassPopup";

import PrivacyPolicyView from "./view";

const PrivacyPolicyController = () => {
  const [showLoginPopup, setShowLoginPopup] = useState({
    show: false,
    mode: "login",
    referralCode: "",
  });
  const [resetPassPopup, setResetPassPopup] = useState({
    show: false,
    mode: "initial",
    code: "",
  });

  return (
    <>
      <PrivacyPolicyView setShowLoginPopup={setShowLoginPopup} />

      <Modal
        open={showLoginPopup.show}
        onClose={() => setShowLoginPopup({ show: false, mode: "register" })}
      >
        <LoginPopup
          close={() => setShowLoginPopup({ show: false, mode: "register" })}
          showResetPassPopup={() =>
            setResetPassPopup({ show: true, mode: "initial", code: "" })
          }
          currentMode={showLoginPopup.mode}
          onSuccess={showLoginPopup?.onSuccess}
        />
      </Modal>
      <Modal
        open={resetPassPopup.show}
        onClose={() =>
          setResetPassPopup({ show: false, mode: "initial", code: "" })
        }
      >
        <ResetPassPopup
          close={() =>
            setResetPassPopup({ show: false, mode: "initial", code: "" })
          }
          currentMode={resetPassPopup.mode}
          code={resetPassPopup.code}
        />
      </Modal>
    </>
  );
};

export default PrivacyPolicyController;
