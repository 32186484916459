import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store";

import PaywallView from "./view";

const PaywallController = () => {
  const navigate = useNavigate();

  const { getProducts } = useStore();

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("user");
  const currentDevice = searchParams.get("device");

  useEffect(() => {
    if (currentDevice) {
      localStorage.setItem("device", currentDevice);
    }

    if (email) {
      getProducts();
      localStorage.setItem("receiverEmail", email);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return <PaywallView email={email} navigate={navigate} />;
};

export default PaywallController;
