import React from "react";
import { useNavigate } from "react-router-dom";

import FooterView from "./view";

const FooterController = ({ isHomePage, isProtectedIp }) => {
  const navigate = useNavigate();

  const scrollToPlans = () => {
    if (isHomePage) {
      const getPlanEl = document.getElementById("prices");
      getPlanEl &&
        getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      navigate("/");
    }
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleClickGetStarted = () =>
    isProtectedIp ? scrollToDownload() : scrollToPlans();

  return <FooterView handleClickGetStarted={handleClickGetStarted} />;
};

export default FooterController;
