import { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const useAnim = ({
  imgRef,
  progress1_ref,
  progress2_ref,
  progress3_ref,
  bar1_ref,
  bar2_ref,
  bar3_ref,
  homeDownloadRef,
}) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: imgRef.current,
        start: "top 50%",
        // markers: true,
      },
    });

    tl.to(imgRef.current, {
      opacity: 1,
      left: "0%",
      duration: 1,
    })
      .to(progress1_ref.current, {
        width: "90%",
        duration: 2,
        delay: 1,
      })
      .to(
        progress2_ref.current,
        {
          width: "95%",
          duration: 2,
        },
        "<"
      )
      .to(
        progress3_ref.current,
        {
          width: "100%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar1_ref.current,
        {
          width: "90%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar2_ref.current,
        {
          width: "95%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar3_ref.current,
        {
          width: "100%",
          duration: 2,
        },
        "<"
      );

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: homeDownloadRef.current,
        start: "top 50%",
      },
    });

    tl2.fromTo(
      ".home-download__item",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.7,
        stagger: 0.7,
      }
    );

    return () => {
      tl.kill();
      tl2.kill();
    };
    // eslint-disable-next-line
  }, []);
};

export { useAnim };
