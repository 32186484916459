import { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { getRem, isDesktopMedia } from "../../../utils";

const useAnim = ({
  progressBlockRef,
  progressRef,
  digitRef,
  btnRef,
  mobileImgWrapperRef,
  animatedContentListRef,
}) => {
  const isDesktop = isDesktopMedia();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: progressBlockRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl1
      .to(progressBlockRef.current, {
        opacity: 1,
        duration: 1,
      })
      .to(digitRef.current, {
        duration: 0.5,
        text: "25%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "25%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 25,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 25);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "50%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "50%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 50,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 50);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "75%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "75%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 75,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 75);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "99%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "99%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 96,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 96);
            }
          },
        },
        "<"
      )
      .to(btnRef.current, {
        opacity: 1,
        duration: 0.5,
      })
      .to(mobileImgWrapperRef.current, {
        opacity: 1,
        duration: 0.5,
      })
      .to(animatedContentListRef.current, {
        opacity: 1,
        duration: 0.5,
        height: isDesktop ? getRem(260) : getRem(220),
        width: isDesktop ? getRem(600) : getRem(360),
      });
    // eslint-disable-next-line
  }, []);
};

export { useAnim };
