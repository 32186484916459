import "./index.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import { isAuthorizedUser } from "../../../utils";

import MainLogo from "../../../assets/main-logo.svg";
import closeIcon from "../../../assets/close_icon.svg";

const MobileMenu = ({ open, setOpen, scrollToPlans, setShowLoginPopup }) => {
  const isAuthorized = isAuthorizedUser();

  const close = () => setOpen(false);

  const navigate = useNavigate();

  return (
    <div className={`mobile-menu ${open ? "active" : ""}`}>
      <div className="mobile-menu_logoContainer">
        <img className="mobile-menu__logo" src={MainLogo} alt="logo" />
        <p>vpn4u.io</p>
      </div>

      <ul>
        <li onClick={close}>
          <a href="#about">About VPN4U</a>
        </li>
        <li onClick={close}>
          <a href="#prices">Prices</a>
        </li>
        <li onClick={close}>
          <a href="#download">Download VPN</a>
        </li>
        <li onClick={close}>
          <a href="#reviews">Reviews</a>
        </li>
        {/*
        <li onClick={close}>
          <a href="#faq">FAQ</a>
        </li>
        */}
      </ul>
      <div className="mobile-menu__btns">
        {/* {!isAuthorized ? (
          <button
            className="default-button alt"
            onClick={() => {
              setShowLoginPopup({ show: true, mode: "login" });
              close();
            }}
          >
            Login
          </button>
        ) : ( */}
        {/* <button
            className="default-button alt"
            onClick={() => navigate("/profile")}
          >
            Your Profile
          </button> */}
        {/* )} */}
        <button
          className="secondary-button"
          onClick={() => {
            scrollToPlans();
            close();
          }}
        >
          Get started
        </button>
        <button
          className="default-button alt"
          onClick={() => {
            if (!isAuthorized) {
              setShowLoginPopup({ show: true, mode: "login" });
            } else {
              navigate("/profile");
            }
          }}
        >
          {isAuthorized ? "Profile" : "Login"}
        </button>
      </div>

      <div className="base-modal__close">
        <img src={closeIcon} onClick={close} alt="close" />
      </div>
    </div>
  );
};

export default MobileMenu;
