const BANNERS = {
  "1080x1080": {
    cls: "w-300",
    items: [
      {
        src: "/images/banners/1080x1080/banner-1-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-1.jpg",
        size: "1,23 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-2-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-2.jpg",
        size: "0,75 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-3-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-3.jpg",
        size: "0,56 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-4-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-4.jpg",
        size: "0,6 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-5-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-5.jpg",
        size: "479 KB",
      },
      {
        src: "/images/banners/1080x1080/banner-6-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-6.jpg",
        size: "0,53 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-7-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-7.jpg",
        size: "0,64 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-8-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-8.jpg",
        size: "0,69 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-9-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-9.jpg",
        size: "0,61 MB",
      },
      {
        src: "/images/banners/1080x1080/banner-10-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-10.jpg",
        size: "451 KB",
      },
      {
        src: "/images/banners/1080x1080/banner-11-light.png",
        downloadSrc: "/images/banners/1080x1080/banner-11.jpg",
        size: "0,64 MB",
      },
    ],
  },
  "830x415": {
    cls: "w-414",
    items: [
      {
        src: "/images/banners/830x415/banner-1-light.png",
        downloadSrc: "/images/banners/830x415/banner-1.jpg",
        size: "0,54 MB",
      },
      {
        src: "/images/banners/830x415/banner-2-light.png",
        downloadSrc: "/images/banners/830x415/banner-2.jpg",
        size: "316 KB",
      },
    ],
  },
};

export { BANNERS };
