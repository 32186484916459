import "./index.scss";

import React from "react";

const AboutView = ({ listRef, benefitsData }) => (
  <section className="about_section" id="about">
    <h2 className="about_title">About VPN4U</h2>
    <p className="about_subtitle">Why should use VPN4U?</p>
    <ul className="about_benefits_list" ref={listRef}>
      {benefitsData.map(({ id, icon, title, text }) => {
        const titleWords = title.split(" ");
        const firstTwoWords = titleWords.slice(0, 2).join(" ");
        const restOfText = titleWords.slice(2).join(" ");

        return (
          <li key={id} className="about_benefits_listItem">
            <img src={icon} alt={`benefit ${id}`} />
            <div>
              <p className="title">
                {firstTwoWords} <br />
                <span>{restOfText}</span>
              </p>
              <p className="text">{text}</p>
            </div>
          </li>
        );
      })}
    </ul>
  </section>
);

export default AboutView;
