import React, { useRef } from "react";

import FeaturesView from "./view";

import { useAnim } from "./useAnim";

const FeaturesController = () => {
  const imgRef = useRef(null);
  const progress1_ref = useRef(null);
  const progress2_ref = useRef(null);
  const progress3_ref = useRef(null);
  const bar1_ref = useRef(null);
  const bar2_ref = useRef(null);
  const bar3_ref = useRef(null);
  const homeDownloadRef = useRef(null);

  useAnim({
    imgRef,
    progress1_ref,
    progress2_ref,
    progress3_ref,
    bar1_ref,
    bar2_ref,
    bar3_ref,
    homeDownloadRef,
  });

  return (
    <FeaturesView
      homeDownloadRef={homeDownloadRef}
      imgRef={imgRef}
      bar1_ref={bar1_ref}
      progress1_ref={progress1_ref}
      bar2_ref={bar2_ref}
      progress2_ref={progress2_ref}
      bar3_ref={bar3_ref}
      progress3_ref={progress3_ref}
    />
  );
};

export default FeaturesController;
