import { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const useAnim = ({
  titleRef,
  desc1Ref,
  desc2Ref,
  btnRef,
  containerRef,
  mapRef,
}) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl1
      .to(titleRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(desc1Ref.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(
        desc2Ref.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        },
        "<"
      )
      .to(btnRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl2.to(
      mapRef.current,
      {
        maskPosition: 0,
        "-webkit-mask-position": 0,
        duration: 2.3,
        ease: "linear",
      },
      "<"
    );
    // eslint-disable-next-line
  }, []);
};

export { useAnim };
