import { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { getRem, isMobileMedia, isTabletMedia } from "../../../utils";

import { SPEEDS, FLAGS_SEQUENCE_ORDER } from "./const";

const useAnim = ({
  benefitsListRef,
  serversRef,
  speedRef,
  speedValueRef,
  speedCircleRef1,
  speedCircleRef2,
  flagRefs,
}) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();

  const runSpeedAnim = () => {
    const elements = [...document.querySelectorAll(".speed-box span")];
    const delay = 350;
    const duration = 3000;

    const splitIndex = elements.length - 11;

    const lastElements = elements.slice(splitIndex);
    const restElements = elements.slice(0, splitIndex);

    [...lastElements, ...restElements].forEach((el, i) => {
      setTimeout(() => {
        el.classList.add("active");
      }, (duration / elements.length) * i + delay);
    });

    SPEEDS.forEach((speed, i) => {
      setTimeout(() => {
        if (speedValueRef.current) {
          speedValueRef.current.textContent = speed;
        }
      }, (duration / SPEEDS.length) * i + delay);
    });

    setTimeout(() => {
      if (speedValueRef.current) {
        speedValueRef.current.classList.add("active");
      }
    }, duration + delay);
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const benefitsListItems = benefitsListRef.current.children;

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: benefitsListRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (isMobile) {
      tl2
        .to([benefitsListItems[0], benefitsListItems[1]], {
          transform: "translate(0, 0)",
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to([benefitsListItems[2], benefitsListItems[3]], {
          transform: "translate(0, 0)",
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        });
    } else {
      Array.from(benefitsListItems).forEach((item, index) => {
        tl2.to(item, {
          opacity: 1,
          transform: "translate(0, 0)",
          duration: 0.5,
          delay: index * 0.1,
          ease: "power2.inOut",
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    gsap.set(flagRefs.current, { autoAlpha: 0 });

    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: serversRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (!isTablet && !isMobile) {
      FLAGS_SEQUENCE_ORDER.desktop.forEach((index, i) => {
        if (i % 3 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.15,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.15,
            delay: 0,
          });
        }
      });
    } else if (isTablet) {
      FLAGS_SEQUENCE_ORDER.tablet.forEach((index, i) => {
        if (i % 4 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.15,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.15,
            delay: 0,
          });
        }
      });
    } else if (isMobile) {
      FLAGS_SEQUENCE_ORDER.mobile.forEach((index, i) => {
        if (i % 5 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.25,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.25,
            delay: 0.15,
          });
        }
      });
    }

    return () => {
      tl3.kill();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: speedRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl4
      .to(speedRef.current, {
        onComplete: runSpeedAnim,
      })
      .to(
        speedCircleRef1.current,
        {
          rotate: 1000,
          duration: 5,
        },
        "<"
      )
      .to(
        speedCircleRef2.current,
        {
          rotate: -1090,
          duration: 5,
        },
        "<"
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const container = document.querySelector(".speed-box");

    const n = 36;

    for (let i = 1; i <= n; i++) {
      const span = document.createElement("span");
      span.textContent = "";
      span.style.transform = `translate(-50%, -50%) rotate(${
        (360 / n) * i
      }deg) translateY(${getRem(80)})`;
      container.appendChild(span);
    }
  }, []);
};

export { useAnim };
