import React, { useState } from "react";

import { TextField } from "@mui/material";

const NumberField = ({ onChange, maxValue, ...props }) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      if (Number(e.target.value) <= Number(maxValue)) {
        setValue(e.target.value);
      } else {
        setValue(maxValue);
      }
    }
  };

  return (
    <TextField
      type="text"
      variant="outlined"
      className="text-field"
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};

export default NumberField;
