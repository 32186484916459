import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { format } from "date-fns";

import { unSubscribe } from "../../../api";

import { useStore } from "../../../store";

import SubscriptionsTabView from "./view";

const SubscriptionsTabController = () => {
  const {
    mySubscription,
    getMySubscription,
    getMySubscriptionLoading: isLoading,
  } = useStore();
  const [isLoadingUnsubscribe, setIsLoadingUnsubscribe] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formattedDate = (date) => format(date, "dd MMM yyyy");

  const handleUnsubscribe = () => {
    setIsLoadingUnsubscribe(true);
    unSubscribe()
      .then(() => {
        enqueueSnackbar("Successfully unsubscribed", { variant: "success" });
        getMySubscription();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoadingUnsubscribe(false);
      });
  };

  return (
    <SubscriptionsTabView
      mySubscription={mySubscription}
      formattedDate={formattedDate}
      isLoading={isLoading || isLoadingUnsubscribe}
      setIsLoadingUnsubscribe={setIsLoadingUnsubscribe}
      handleUnsubscribe={handleUnsubscribe}
    />
  );
};

export default SubscriptionsTabController;
