import React from "react";

import { useStore } from "../../../store";

import ReferralView from "./view";

const ReferralController = () => {
  const { referralInfo } = useStore();

  return <ReferralView referralInfo={referralInfo} />;
};

export default ReferralController;
