import { useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import { Lucid, Blockfrost } from "lucid-cardano";

import { useStore } from "../store";

import { openInNewTab } from "../utils";

import {
  subscribePaypal,
  subscribeStripe,
  subscribeStripeNA,
  subscribeCryptoAPI,
  subscribeCryptoNaAPI,
} from "../api";

import {
  BLOCKFROST_API,
  BLOCKFROST_PROJECT_ID,
  INNER_WALLET_CRYPTO_PAYMENTS,
  C4_TOKEN_ID,
} from "../const/config";

const useSelectPlan = ({ email } = {}) => {
  const [paymentMethodPopup, setPaymentMethodPopup] = useState({
    show: false,
    payViaStripe: () => null,
    payViaPaypal: () => null,
    payViaCrypto: () => null,
    selectedPlan: {},
    freeTrial: false,
  });

  const withAuth = !email;

  const { getProductsLoading, getAvailableWallets } = useStore();

  const { enqueueSnackbar } = useSnackbar();

  const handleSelectPlan = (selectedPlan, isFreeTrial) => {
    if (getProductsLoading) {
      return;
    }

    const payViaStripe = ({ promocode } = {}) => {
      const subscribe = withAuth ? subscribeStripe : subscribeStripeNA;

      subscribe({
        plan: selectedPlan.stripe.id,
        isFreeTrial: !!isFreeTrial,
        ...(withAuth ? {} : { email }),
        ...(promocode ? { promocode } : {}),
      }).then((response) => {
        const checkoutUrl = response.data.checkout_url;
        openInNewTab(checkoutUrl);
      });
    };

    const payViaPaypal = ({ promocode } = {}) => {
      subscribePaypal({
        plan: selectedPlan.paypal.id,
        isFreeTrial: !!isFreeTrial,
        ...(promocode ? { promocode } : {}),
      }).then((response) => {
        const checkoutUrl = response.data.redirect_url;
        openInNewTab(checkoutUrl);
      });
    };

    const payViaCrypto = async ({ onSuccess, onFail }) => {
      const lucid = await Lucid.new(
        new Blockfrost(BLOCKFROST_API, BLOCKFROST_PROJECT_ID)
      );
      const connectedWallet = localStorage.getItem("connectedWallet");

      try {
        const api = await window.cardano[connectedWallet].enable();
        lucid.selectWallet(api);

        const tx = await lucid
          .newTx()
          .payToAddress(INNER_WALLET_CRYPTO_PAYMENTS, {
            //            lovelace: amount * 1000000,
            [C4_TOKEN_ID]: selectedPlan.crypto.price,
          })
          .complete();

        const signedTx = await tx.sign().complete();
        const txHash = await signedTx.submit();

        const subscribeCrypto = withAuth
          ? subscribeCryptoAPI
          : subscribeCryptoNaAPI;

        subscribeCrypto({
          plan: selectedPlan.crypto.id,
          amount: selectedPlan.crypto.price,
          transaction_id: txHash,
          ...(withAuth ? {} : { email }),
        })
          .then(() => {
            onSuccess();
            enqueueSnackbar("Successfully subscribed", { variant: "success" });
          })
          .catch((e) => {
            console.log(e);
            onFail();
            enqueueSnackbar(e.response.data.message, { variant: "error" });
          });
      } catch (e) {
        console.log(e);
        onFail();
        if (e === "InputsExhaustedError") {
          enqueueSnackbar("Insufficient funds", {
            variant: "error",
          });
        }
        // e?.code === 2 means user declined transaction
        else if (e?.code !== 2 && e?.code !== -3) {
          // Unknown error
          enqueueSnackbar(
            "There is an issue with the wallet, please make sure collateral is set, also consider using fresh wallet",
            { variant: "error" }
          );
        }
      }
    };

    if (withAuth) {
      // FOR HOME & PROFILE PAGES
      setPaymentMethodPopup({
        show: true,
        selectedPlan: selectedPlan,
        freeTrial: !!isFreeTrial,
        payViaStripe,
        payViaPaypal,
        payViaCrypto,
      });
    } else {
      // FOR PAYWALL PAGE
      setPaymentMethodPopup({
        show: true,
        selectedPlan: selectedPlan,
        freeTrial: !!isFreeTrial,
        payViaStripe,
        payViaCrypto,
      });
    }
  };

  useEffect(() => {
    getAvailableWallets();
    // eslint-disable-next-line
  }, []);

  return { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup };
};

export { useSelectPlan };
