import React, { useRef } from "react";

import AboutView from "./view";

import { useAnim } from "./useAnim";

import { BENEFITS_DATA } from "./const";

const AboutController = () => {
  const listRef = useRef(null);

  useAnim({ listRef });

  return <AboutView benefitsData={BENEFITS_DATA} listRef={listRef} />;
};

export default AboutController;
