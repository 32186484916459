import ben1 from "../../../assets/iconBenefit1.svg";
import ben2 from "../../../assets/iconBenefit2.svg";
import ben3 from "../../../assets/iconBenefit3.svg";
import ben4 from "../../../assets/iconBenefit4.svg";
import Flag1 from "../../../assets/flags/flag-1.png";
import Flag2 from "../../../assets/flags/flag-2.png";
import Flag3 from "../../../assets/flags/flag-3.png";
import Flag4 from "../../../assets/flags/flag-4.png";
import Flag5 from "../../../assets/flags/flag-5.png";
import Flag6 from "../../../assets/flags/flag-6.png";
import Flag7 from "../../../assets/flags/flag-7.png";
import Flag8 from "../../../assets/flags/flag-8.png";
import Flag9 from "../../../assets/flags/flag-9.png";
import Flag10 from "../../../assets/flags/flag-10.png";
import Flag11 from "../../../assets/flags/flag-11.png";
import Flag12 from "../../../assets/flags/flag-12.png";
import Flag13 from "../../../assets/flags/flag-13.png";
import Flag14 from "../../../assets/flags/flag-14.png";
import Flag15 from "../../../assets/flags/flag-15.png";
import Flag16 from "../../../assets/flags/flag-16.png";
import Flag17 from "../../../assets/flags/flag-17.png";
import Flag18 from "../../../assets/flags/flag-18.png";
import Flag19 from "../../../assets/flags/flag-19.png";
import Flag20 from "../../../assets/flags/flag-20.png";
import Flag21 from "../../../assets/flags/flag-21.png";
import Flag22 from "../../../assets/flags/flag-22.png";
import Flag23 from "../../../assets/flags/flag-23.png";
import Flag24 from "../../../assets/flags/flag-24.png";
import Flag25 from "../../../assets/flags/flag-25.png";
import Flag26 from "../../../assets/flags/flag-26.png";
import Flag27 from "../../../assets/flags/flag-27.png";
import Flag28 from "../../../assets/flags/flag-28.png";
import Flag29 from "../../../assets/flags/flag-29.png";
import Flag30 from "../../../assets/flags/flag-30.png";

const FLAGS = [
  Flag26,
  Flag19,
  Flag5,
  Flag25,
  Flag29,
  Flag30,
  Flag4,
  Flag21,
  Flag23,
  Flag22,
  Flag20,
  Flag10,
  Flag9,
  Flag24,
  Flag18,
  Flag12,
  Flag7,
  Flag17,
  Flag8,
  Flag11,
  Flag15,
  Flag27,
  Flag3,
  Flag16,
  Flag6,
  Flag1,
  Flag2,
  Flag14,
  Flag13,
  Flag28,
];

const SPEEDS = [
  "0 MB/s",
  "56 MB/s",
  "112 MB/s",
  "169 MB/s",
  "226 MB/s",
  "283 MB/s",
  "340 MB/s",
  "397 MB/s",
  "454 MB/s",
  "511 MB/s",
  "568 MB/s",
  "625 MB/s",
  "682 MB/s",
  "739 MB/s",
  "796 MB/s",
  "853 MB/s",
  "910 MB/s",
  "967 MB/s",
  "1024 MB/s",
  "1 GB/s",
];

const BENEFITS_DATA = [
  {
    id: 1,
    icon: ben1,
    digit: "2000+",
    text: "Servers Active",
  },
  {
    id: 2,
    icon: ben2,
    digit: "31+",
    text: "Countries",
  },
  {
    id: 3,
    icon: ben3,
    digit: "100%",
    text: "Money Back",
  },
  {
    id: 4,
    icon: ben4,
    digit: "24/7",
    text: "Support",
  },
];

const FLAGS_SEQUENCE_ORDER = {
  mobile: [...new Array(30)].map((_, i) => i),
  tablet: [
    0, 30, 30, 30, 1, 8, 30, 30, 2, 9, 16, 30, 3, 10, 17, 30, 4, 11, 18, 24, 5,
    12, 19, 25, 6, 13, 20, 26, 7, 14, 21, 27, 15, 22, 28, 30, 23, 29, 30, 30,
  ],
  desktop: [
    0, 30, 30, 1, 10, 30, 2, 11, 20, 3, 12, 21, 4, 13, 22, 5, 14, 23, 6, 15, 24,
    7, 16, 25, 8, 17, 26, 9, 18, 27, 19, 28, 30, 29, 30, 30,
  ],
};

export { FLAGS, BENEFITS_DATA, SPEEDS, FLAGS_SEQUENCE_ORDER };
