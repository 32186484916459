import "./index.scss";

import React from "react";

import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";

import { CONTACTS } from "../../const/config";

const TermsView = ({ setShowLoginPopup }) => (
  <div className="terms-wrapper">
    <Header setShowLoginPopup={setShowLoginPopup} />

    <div className="terms">
      <h1>Terms and Conditions of VPN4U by Quanta Tech Systems LLC</h1>

      <h3>1. Introduction</h3>

      <p>
        Welcome to VPN4U, a service provided by Quanta Tech Systems LLC (“Quanta
        Tech”), a company based in New York, USA. By using the VPN4U services
        (“Service”), you agree to be bound by the following terms and conditions
        (“Terms”). If you do not agree with these Terms, you are not authorized
        to use this Service.
      </p>
      <br />
      <h3>2. Privacy and Data Security</h3>

      <p>
        Quanta Tech is committed to protecting your privacy and personal
        information. The Service encrypts your data to ensure security and
        anonymity over the Internet. Details of our data collection, processing,
        and storage practices are described in our Privacy Policy.
      </p>
      <br />
      <h3>3. User Obligations</h3>

      <p>By using VPN4U, you agree to:</p>
      <ul>
        <li>Not use the Service for any unlawful activities.</li>
        <li>
          Not engage in any activity that interferes with or disrupts the
          Service or servers or networks connected to the Service.
        </li>
        <li>
          Comply with all applicable local, state, national, and international
          laws and regulations.
        </li>
      </ul>
      <br />
      <h3>4. Service Availability</h3>

      <p>
        Quanta Tech strives to ensure reliability and availability of the
        Service, but makes no guarantees that the Service will be uninterrupted
        or error-free. Maintenance and updates are necessary and can lead to
        temporary unavailability of the Service.
      </p>
      <br />
      <h3>5. Intellectual Property</h3>

      <p>
        All content included on the Service, such as text, graphics, logos,
        images, as well as the compilation thereof, and any software used on the
        Site, is the property of Quanta Tech Systems LLC or its suppliers and
        protected by copyright and other laws that protect intellectual property
        and proprietary rights.
      </p>
      <br />
      <h3>6. Termination and Suspension</h3>

      <p>
        Quanta Tech may terminate or suspend your access to the Service
        immediately, without prior notice or liability, for any reason
        whatsoever, including, without limitation, if you breach the Terms.
      </p>
      <br />
      <h3>7. Changes to Terms</h3>

      <p>
        Quanta Tech reserves the right, at its sole discretion, to modify or
        replace these Terms at any time. By continuing to access or use our
        Service after those revisions become effective, you agree to be bound by
        the revised terms.
      </p>
      <br />
      <h3>8. Governing Law</h3>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of New York, United States, without regard to its conflict of law
        provisions.
      </p>
      <br />
      <h3>9. Contact Us</h3>

      <p>If you have any questions about these Terms, please contact us at:</p>

      <p>
        Email:{" "}
        <a href={`mailto:${CONTACTS.supportQTech}`}>{CONTACTS.supportQTech}</a>
      </p>
      <p>Address: {CONTACTS.address}</p>
    </div>
    <Footer />
  </div>
);

export default TermsView;
