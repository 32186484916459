import "./index.scss";

import React from "react";

import { CONTACTS } from "../../../const/config";

const Footer = () => (
  <div className="paywall-footer">
    <span className="paywall-footer__privacy-rights">
      © 2024 vpn4u.io, All Rights Reserved.
      <br />
      Made by Quanta Tech Systems LLC
    </span>
    <span className="paywall-footer__support">
      Support email:{" "}
      <a href={`mailto:${CONTACTS.supportVPN4u}`}>{CONTACTS.supportVPN4u}</a>
    </span>

    <span className="paywall-footer__privacy-btn">
      <a href="/privacy-policy">Privacy Policy</a>
    </span>
    <span className="paywall-footer__privacy-btn">
      <a href="/terms">Terms and Conditions</a>
    </span>
  </div>
);

export default Footer;
