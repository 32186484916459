import React from "react";

import { LoaderV2 } from "../../ui/Loader";

const BillingTabView = ({ isLoading, paymentHistory, formattedDate }) => (
  <div className="profile-billing">
    <h2>Billing History</h2>
    <div
      className="profile-billing__box"
      style={isLoading || !paymentHistory?.length ? { border: "none" } : {}}
    >
      {isLoading ? (
        <LoaderV2 />
      ) : paymentHistory?.length ? (
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory.map((item, i) => (
              <tr key={i}>
                <td>VPN4U.IO</td>
                <td>
                  {item.amount.toFixed(2)}
                  &nbsp;
                  {item.currency === "usd" ? "$" : item.currency}
                </td>
                <td>{formattedDate(item.transaction_date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="profile-billing__empty">There are no billing history</p>
      )}
    </div>
  </div>
);

export default BillingTabView;
