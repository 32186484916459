import React from "react";

import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import Button from "../../Button";

import { logIn } from "../../../../api";

const LoginForm = ({
  switchToRegister,
  switchToResetPass,
  setIsLoading,
  onSuccess,
  close,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    const data = { email, password };

    setIsLoading(true);

    logIn(data)
      .then((response) => {
        enqueueSnackbar("You have successfully logged in!", {
          variant: "success",
        });
        const accessToken = response.data.token;
        localStorage.setItem("accessToken", accessToken);

        // if user tried to do subscription - do it
        //onSuccess ? onSuccess() : scrollToPlans();
        onSuccess && onSuccess();
        close();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form id="login-form" onSubmit={handleSubmit}>
      <h2 className="base-modal__title">Login</h2>
      <p className="mb-40">Please enter your email and password</p>
      <TextField
        required
        name="email"
        type="email"
        id="outlined-basic4"
        label="Your email"
        variant="outlined"
        className="text-field"
        sx={{
          "& input:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        }}
      />
      <TextField
        required
        name="password"
        type="password"
        id="outlined-basic5"
        label="Your password"
        variant="outlined"
        className="text-field"
        sx={{
          "& input:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        }}
      />
      <p className="extra-bold mb-40 pointer" onClick={switchToResetPass}>
        Forgot password?
      </p>
      <Button mode="secondary" type="submit" fullWidth>
        {onSuccess ? "Next step" : "Log in"}
      </Button>
      <p className="lh-150 mt-20 mb-5">Or</p>
      <Button onClick={switchToRegister} fullWidth>
        Register
      </Button>
    </form>
  );
};

export default LoginForm;
