import React, { useRef, useState } from "react";

import { useStore } from "../../../store";

import HeroView from "./view";

import { useAnim } from "./useAnim";

import { PROTECTING_STATUS } from "./const";

const HeroController = ({ isProtectedIp }) => {
  const listRef = useRef(null);
  const priceRef = useRef(null);
  const btnBoxRef = useRef(null);
  const animationBlockRef = useRef(null);
  const mobile2Ref = useRef(null);
  const mobile1Ref = useRef(null);

  useAnim({
    listRef,
    priceRef,
    btnBoxRef,
    animationBlockRef,
    mobile1Ref,
    mobile2Ref,
  });

  const [isActivating, setIsActivating] = useState(false);

  const { ipInfo, getIpInfoLoading, getServersLoading } = useStore();

  const loading = getIpInfoLoading || getServersLoading;

  const scrollToPlans = () => {
    const getPlanEl = document.getElementById("prices");
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleClickGetStarted = () => {
    if (isProtectedIp) {
      scrollToDownload();
    } else if (!isActivating) {
      setIsActivating(true);
      setTimeout(scrollToPlans, 1500);
    } else {
      scrollToPlans();
    }
  };

  const protectingStatus = loading
    ? PROTECTING_STATUS.loading
    : isProtectedIp
    ? PROTECTING_STATUS.protected
    : PROTECTING_STATUS.unprotected;

  return (
    <HeroView
      ipInfo={ipInfo}
      isActivating={isActivating}
      protectingStatus={protectingStatus}
      handleClickGetStarted={handleClickGetStarted}
      // refs
      listRef={listRef}
      priceRef={priceRef}
      btnBoxRef={btnBoxRef}
      animationBlockRef={animationBlockRef}
      mobile1Ref={mobile1Ref}
      mobile2Ref={mobile2Ref}
    />
  );
};

export default HeroController;
