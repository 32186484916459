const CRYPTO_PLAN_NAMES = {
  oneYearPlan: "yearly_plan_cardano",
};

/** MATCH PLANS WHICH WE USE */
const receivePaymentPlans = (
  stripePricesResp,
  paypalPricesResp,
  cryptoPricesResp
) => {
  const stripePrices = stripePricesResp.prices;
  const paypalPrices = paypalPricesResp.pricing?.plans
    ? paypalPricesResp.pricing.plans.filter(
        (price) => price.status === "ACTIVE"
      )
    : [];

  const cryptoPrices = cryptoPricesResp.plans.filter(
    (plan) => plan.status === "active"
  );

  let oneWeekPlanStripe, oneMonthPlanStripe, oneYearPlanStripe;
  let oneWeekPlanPaypal,
    oneMonthPlanPaypal,
    oneYearPlanPaypal,
    oneYearTrialPlanPaypal;
  let oneYearPlanCrypto;

  stripePrices.forEach((price) => {
    if (price.recurring) {
      if (
        price.recurring.interval === "week" &&
        price.recurring.interval_count === 1
      ) {
        oneWeekPlanStripe = price;
      } else if (
        price.recurring.interval === "month" &&
        price.recurring.interval_count === 1
      ) {
        oneMonthPlanStripe = price;
      } else if (
        price.recurring.interval === "year" &&
        price.recurring.interval_count === 1
      ) {
        oneYearPlanStripe = price;
      }
    }
  });

  paypalPrices.forEach((price) => {
    if (
      price.billing_cycles.find(
        (cycle) =>
          cycle.tenure_type === "REGULAR" &&
          cycle.frequency.interval_count === 1 &&
          cycle.frequency.interval_unit === "WEEK"
      )
    ) {
      oneWeekPlanPaypal = price;
    }
    if (
      price.billing_cycles.find(
        (cycle) =>
          cycle.tenure_type === "REGULAR" &&
          cycle.frequency.interval_count === 1 &&
          cycle.frequency.interval_unit === "MONTH"
      )
    ) {
      oneMonthPlanPaypal = price;
    }

    if (
      price.billing_cycles.find(
        (cycle) =>
          cycle.tenure_type === "REGULAR" &&
          cycle.frequency.interval_count === 1 &&
          cycle.frequency.interval_unit === "YEAR"
      ) &&
      !price.billing_cycles.find((cycle) => cycle.tenure_type === "TRIAL")
    ) {
      oneYearPlanPaypal = price;
    }

    if (
      price.billing_cycles.find(
        (cycle) =>
          cycle.tenure_type === "REGULAR" &&
          cycle.frequency.interval_count === 1 &&
          cycle.frequency.interval_unit === "YEAR"
      ) &&
      price.billing_cycles.find((cycle) => cycle.tenure_type === "TRIAL")
    ) {
      oneYearTrialPlanPaypal = price;
    }
  });

  oneYearPlanCrypto = cryptoPrices.find(
    (price) => price.name === CRYPTO_PLAN_NAMES.oneYearPlan
  );

  return {
    ONE_WEEK_PLAN: {
      paypal: oneWeekPlanPaypal,
      stripe: {
        ...oneWeekPlanStripe,
        unit_amount_monthly: oneWeekPlanStripe.unit_amount.toFixed(2),
      },
    },
    ONE_MONTH_PLAN: {
      paypal: oneMonthPlanPaypal,
      stripe: {
        ...oneMonthPlanStripe,
        unit_amount_monthly: oneMonthPlanStripe.unit_amount.toFixed(2),
      },
    },
    ONE_YEAR_PLAN: {
      paypal: oneYearPlanPaypal,
      stripe: {
        ...oneYearPlanStripe,
        unit_amount_monthly: (oneYearPlanStripe.unit_amount / 12).toFixed(2),
        discount:
          100 -
          Math.round(
            (oneYearPlanStripe.unit_amount /
              12 /
              oneMonthPlanStripe.unit_amount) *
              100
          ),
      },
      crypto: oneYearPlanCrypto,
    },
    ONE_YEAR_TRIAL_PLAN: {
      paypal: oneYearTrialPlanPaypal,
      stripe: {
        ...oneYearPlanStripe,
        unit_amount_monthly: (oneYearPlanStripe.unit_amount / 12).toFixed(2),
        discount:
          100 -
          Math.round(
            (oneYearPlanStripe.unit_amount /
              12 /
              oneMonthPlanStripe.unit_amount) *
              100
          ),
      },
    },
  };
};

export { receivePaymentPlans };
