import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";

import { useStore } from "../../store";

import { emailVerify, resetPasswordCheck } from "../../api";

import { isAuthorizedUser } from "../../utils";

import HomeView from "./view";

import LoginPopup from "../ui/LoginPopup";
import ResetPassPopup from "../ui/ResetPassPopup";
import SuccessPaymentPopup from "../ui/SuccessPaymentPopup";
import PaymentMethodPopup from "../ui/PaymentMethodPopup";

import { default as PaywallSuccessPaymentPopup } from "../PaywallPage/SuccessPaymentPopup";
import ConnectWalletModal from "../ui/ConnectWalletModal";

import { useSelectPlan } from "../../hooks/useSelectPlan";

const HomeController = () => {
  const navigate = useNavigate();

  const [showLoginPopup, setShowLoginPopup] = useState({
    show: false,
    mode: "register",
  });
  const [resetPassPopup, setResetPassPopup] = useState({
    show: false,
    mode: "initial",
    code: "",
  });
  const [connectWalletModal, setConnectWalletModal] = useState({
    show: false,
    onSuccess: () => null,
    onFail: () => null,
  });
  const [showSuccessPaymentPopup, setShowSuccessPaymentPopup] = useState(false);
  const [showSuccessPaymentAppPopup, setShowSuccessPaymentAppPopup] =
    useState(false);
  const [getPlanEl, setGetPlanEl] = useState(null);

  const ipInfo = useStore((state) => state.ipInfo);
  const servers = useStore((state) => state.servers);
  const getHomeData = useStore((state) => state.getHomeData);

  const { enqueueSnackbar } = useSnackbar();

  const authorizeRequest = ({ onSuccess }) => {
    setShowLoginPopup({
      show: true,
      mode: "register",
      onSuccess: onSuccess,
    });
  };

  const { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup } =
    useSelectPlan();

  const isAuthorized = isAuthorizedUser();

  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = useMemo(
    () => ({
      confirmEmailCode: searchParams.get("email-code"),
      resetPassCode: searchParams.get("reset-pass-code"),
      referralCode: searchParams.get("referral-code"),
      errorMessage: searchParams.get("error-message"),
      showSuccessfulPayment: searchParams.get("show-successful-payment"),
      fromApp: searchParams.get("from_app"),
    }),
    // eslint-disable-next-line
    [searchParams]
  );
  const device = localStorage.getItem("device");

  const isProtectedIp = servers.map((server) => server.ip).includes(ipInfo.ip);

  const scrollToPlans = () => {
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleConfirmEmail = (code) => {
    emailVerify({ code })
      .then(() => {
        enqueueSnackbar("You have successfully verified email!", {
          variant: "success",
        });
        setShowLoginPopup({ show: true, mode: "login" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        navigate("/");
      });
  };

  const handleResetPass = (code) => {
    resetPasswordCheck({ code })
      .then(() => {
        setResetPassPopup({ show: true, mode: "new-pass", code });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        navigate("/");
      });
  };

  const handleRegisterWithRefCode = () => {
    !isAuthorized
      ? setShowLoginPopup({
          show: true,
          mode: "register",
        })
      : navigate("/");
  };

  useEffect(() => {
    queryParams.confirmEmailCode &&
      handleConfirmEmail(queryParams.confirmEmailCode);

    queryParams.resetPassCode && handleResetPass(queryParams.resetPassCode);

    queryParams.referralCode && handleRegisterWithRefCode();

    if (queryParams.errorMessage) {
      enqueueSnackbar(queryParams.errorMessage, { variant: "error" });
      navigate("/");
    }

    if (queryParams.showSuccessfulPayment) {
      if (device === "android") {
        setShowSuccessPaymentAppPopup(true);
        navigate("/");
      } else {
        setShowSuccessPaymentPopup(true);
        navigate("/");
      }
    }

    if (queryParams.fromApp) {
      if (!isAuthorized) {
        setShowLoginPopup({
          show: true,
          mode: "login",
          onSuccess: scrollToPlans,
        });
      } else {
        scrollToPlans();
      }
    }

    // eslint-disable-next-line
  }, [queryParams]);

  useEffect(() => {
    getHomeData();

    const foundEl = document.getElementById("get-plan");
    setGetPlanEl(foundEl);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HomeView
        isProtectedIp={isProtectedIp}
        setShowLoginPopup={setShowLoginPopup}
        handleSelectPlan={handleSelectPlan}
      />

      <Modal
        open={showLoginPopup.show}
        onClose={() => setShowLoginPopup({ show: false, mode: "register" })}
      >
        <LoginPopup
          close={() => setShowLoginPopup({ show: false, mode: "register" })}
          showResetPassPopup={() =>
            setResetPassPopup({ show: true, mode: "initial", code: "" })
          }
          currentMode={showLoginPopup.mode}
          referralCode={queryParams.referralCode}
          onSuccess={showLoginPopup?.onSuccess}
          scrollToPlans={scrollToPlans}
        />
      </Modal>
      <Modal
        open={resetPassPopup.show}
        onClose={() =>
          setResetPassPopup({ show: false, mode: "initial", code: "" })
        }
      >
        <ResetPassPopup
          close={() =>
            setResetPassPopup({ show: false, mode: "initial", code: "" })
          }
          currentMode={resetPassPopup.mode}
          code={resetPassPopup.code}
        />
      </Modal>
      <Modal
        open={showSuccessPaymentPopup}
        onClose={() => setShowSuccessPaymentPopup(false)}
      >
        <SuccessPaymentPopup close={() => setShowSuccessPaymentPopup(false)} />
      </Modal>
      <Modal
        open={showSuccessPaymentAppPopup}
        onClose={() => setShowSuccessPaymentAppPopup(false)}
      >
        <PaywallSuccessPaymentPopup
          close={() => setShowSuccessPaymentAppPopup(false)}
        />
      </Modal>

      <Modal
        open={paymentMethodPopup.show}
        onClose={() => setPaymentMethodPopup({ show: false })}
      >
        <PaymentMethodPopup
          close={() => setPaymentMethodPopup({ show: false })}
          payViaStripe={paymentMethodPopup.payViaStripe}
          payViaPaypal={paymentMethodPopup.payViaPaypal}
          payViaCrypto={paymentMethodPopup.payViaCrypto}
          freeTrial={paymentMethodPopup.freeTrial}
          selectedPlan={paymentMethodPopup.selectedPlan}
          authorizeRequest={authorizeRequest}
          setConnectWalletModal={setConnectWalletModal}
        />
      </Modal>
      <Modal
        open={connectWalletModal.show}
        onClose={() => {
          setConnectWalletModal({ show: false });
          connectWalletModal.onFail();
        }}
      >
        <ConnectWalletModal
          close={() => {
            setConnectWalletModal({ show: false });
            connectWalletModal.onFail();
          }}
          onSuccess={connectWalletModal.onSuccess}
        />
      </Modal>
    </>
  );
};

export default HomeController;
