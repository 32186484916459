import "./index.scss";

import React from "react";

import SpeedBenefitImg from "../../../assets/speed-benefit.svg";

const AdvantagesView = ({
  FLAGS,
  benefitsData,
  flagRefs,
  serversRef,
  speedRef,
  speedCircleRef1,
  speedCircleRef2,
  speedValueRef,
  benefitsListRef,
}) => (
  <div className="advantages">
    <section className="advantages-servers" ref={serversRef}>
      <h2 className="advantages-servers__title">
        Discover the digital universe
      </h2>
      <p className="advantages-servers__subtitle">
        Servers from around the world
      </p>
      <div className="advantages-servers__box">
        {FLAGS.map((flag, i) => (
          <img
            src={flag}
            alt="flag"
            key={i}
            ref={(el) => (flagRefs.current[i] = el)}
          />
        ))}
      </div>
    </section>

    <section className="advantages-speed" ref={speedRef}>
      <h2 className="advantages-servers__title">
        Don't limit yourself in possibilities
      </h2>
      <p className="advantages-servers__subtitle">
        Advanced technologies ensure performance
      </p>
      <div className="advantages-speed__container">
        <p className="advantages-speed__title">
          High data
          <br /> transfer speed
        </p>
        <div className="speed-box">
          <div className="advantages-circle-1_" ref={speedCircleRef1} />
          <div className="advantages-circle-2_" ref={speedCircleRef2} />
          <img src={SpeedBenefitImg} alt="speed" />
        </div>
        <p className="advantages-speed__subtitle" ref={speedValueRef}>
          0 MB/s
        </p>
      </div>
    </section>

    <section className="benefits_section">
      <ul className="benefits_list" ref={benefitsListRef}>
        {benefitsData.map(({ id, icon, digit, text }) => (
          <li className="benefits_listItem" key={id}>
            <img src={icon} alt={`benefit ${id}`} />
            <p className="digit">{digit}</p>
            <p className="text">{text}</p>
          </li>
        ))}
      </ul>
    </section>
  </div>
);

export default AdvantagesView;
