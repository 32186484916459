import { useEffect } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../../utils";

const useAnim = ({ listRef }) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();
  const isDesktop = isDesktopMedia();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const items = listRef.current.children;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: listRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (isMobile || isTablet) {
      tl.to([...items], {
        top: 0,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
    } else if (isDesktop) {
      tl.to(listRef.current, {
        duration: 1,
        opacity: 1,
        width: getRem(1200),
        ease: "power2.inOut",
      });
    }
    // eslint-disable-next-line
  }, []);
};

export { useAnim };
