import React, { useState } from "react";
import { Modal } from "@mui/material";

import PlanTable from "./PlanTable";

import SuccessPaymentPopup from "../../ui/SuccessPaymentPopup";

const PlansTab = ({ handleSelectPlan }) => {
  const [showSuccessPaymentPopup, setShowSuccessPaymentPopup] = useState(false);

  return (
    <div className="profile-plans">
      <PlanTable
        showSuccessPayment={() => setShowSuccessPaymentPopup(true)}
        handleSelectPlan={handleSelectPlan}
      />

      <Modal
        open={showSuccessPaymentPopup}
        onClose={() => setShowSuccessPaymentPopup(false)}
      >
        <SuccessPaymentPopup close={() => setShowSuccessPaymentPopup(false)} />
      </Modal>
    </div>
  );
};

export default PlansTab;
