import React, { useRef } from "react";

import { isDesktopMedia } from "../../../utils";

import { useStore } from "../../../store";

import WeOfferView from "./view";

import { useAnim } from "./useAnim";

import { ANIMATION_TEXT_CONTENT } from "./const";

const WeOfferController = ({ handleSelectPlan }) => {
  const isDesktop = isDesktopMedia();

  const progressBlockRef = useRef(null);
  const progressRef = useRef(null);
  const digitRef = useRef(null);
  const btnRef = useRef(null);
  const mobileImgWrapperRef = useRef(null);
  const animatedContentListRef = useRef(null);

  const { ONE_YEAR_TRIAL_PLAN } = useStore();

  useAnim({
    progressBlockRef,
    progressRef,
    digitRef,
    btnRef,
    mobileImgWrapperRef,
    animatedContentListRef,
  });

  return (
    <WeOfferView
      handleSelectPlan={handleSelectPlan}
      ONE_YEAR_TRIAL_PLAN={ONE_YEAR_TRIAL_PLAN}
      progressViewBox={isDesktop ? "0 0 160 160" : "0 0 100 100"}
      animationTextContent={ANIMATION_TEXT_CONTENT}
      // refs
      progressBlockRef={progressBlockRef}
      progressRef={progressRef}
      digitRef={digitRef}
      btnRef={btnRef}
      mobileImgWrapperRef={mobileImgWrapperRef}
      animatedContentListRef={animatedContentListRef}
    />
  );
};

export default WeOfferController;
