import "./index.scss";

import React from "react";

import Footer from "./Footer";
import Plans from "./Plans";

const PaywallView = ({ email, navigate }) => (
  <>
    <div className="paywall-page">
      <header>
        <div
          className="paywall-logo"
          onClick={() => navigate("/")}
          style={{ margin: "0 auto" }}
        >
          <img src="/images/logo-vpn4u.svg" alt="VPN logo" />
        </div>
      </header>
      <Plans email={email} />

      <Footer />
    </div>
  </>
);

export default PaywallView;
