import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import HeaderView from "./view";
import MobileMenu from "../../ui/MobileMenu";

import { isAuthorizedUser } from "../../../utils";

const HeaderController = ({ setShowLoginPopup, isHomePage, isProtectedIp }) => {
  const navigate = useNavigate();

  const isAuthorized = isAuthorizedUser();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToPlans = () => {
    const getPlanEl = document.getElementById("prices");
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const profileBtnTitle = isAuthorized ? "Profile" : "Login";

  const handleClickProfileBtn = () => {
    if (!isAuthorized) {
      setShowLoginPopup({ show: true, mode: "login" });
    } else {
      navigate("/profile");
    }
  };

  const handleClickGetStarted = () =>
    isProtectedIp ? scrollToDownload() : scrollToPlans();

  return (
    <>
      <HeaderView
        isHomePage={isHomePage}
        navigate={navigate}
        profileBtnTitle={profileBtnTitle}
        handleClickProfileBtn={handleClickProfileBtn}
        handleClickGetStarted={handleClickGetStarted}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <MobileMenu
        open={isMobileMenuOpen}
        setOpen={setIsMobileMenuOpen}
        scrollToPlans={scrollToPlans}
        setShowLoginPopup={setShowLoginPopup}
      />
    </>
  );
};

export default HeaderController;
