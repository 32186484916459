import "./index.scss";

import React from "react";

const WeOfferView = ({
  handleSelectPlan,
  progressBlockRef,
  progressViewBox,
  progressRef,
  digitRef,
  btnRef,
  ONE_YEAR_TRIAL_PLAN,
  mobileImgWrapperRef,
  animatedContentListRef,
  animationTextContent,
}) => (
  <section className="weOffer_section">
    <div className="weOffer_card">
      <div className="weOffer_textContent">
        <div>
          <h3 className="weOffer_title">What we offer</h3>
          <p className="weOffer_subtitle">
            Your shortest way
            <br /> to a safe Internet
          </p>
        </div>
        <div className="weOffer_progressBlock" ref={progressBlockRef}>
          <svg
            viewBox={progressViewBox}
            className="circular-progress"
            ref={progressRef}
          >
            <circle className="fg"></circle>
          </svg>
          <div>
            <p className="digit" ref={digitRef}>
              1%
            </p>
            <p className="desc">Digital security and freedom</p>
          </div>
        </div>
        <button
          className="secondary-button weOffer_trialBtn"
          ref={btnRef}
          onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
        >
          Get free trial
        </button>
      </div>
      <div className="weOffer_imageWrapper" ref={mobileImgWrapperRef}>
        <ul className="animatedContent_list" ref={animatedContentListRef}>
          <li className="animatedContent_listItem">
            {animationTextContent[0]}
          </li>
          <li className="animatedContent_listItem">
            {animationTextContent[1]}
          </li>
          <li className="animatedContent_listItem">
            {animationTextContent[2]}
          </li>
          <li className="animatedContent_listItem">
            {animationTextContent[3]}
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default WeOfferView;
