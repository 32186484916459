import "./index.scss";

import React, { useState } from "react";

import { useSnackbar } from "notistack";

import { withdrawFundsAPI } from "../../../api";

import { useStore } from "../../../store";

import WithdrawPopupView from "./view";

const WithdrawPopupController = ({ close }) => {
  // mode: initial | confirm
  const [mode, setMode] = useState("initial");

  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState({
    amount: 0,
    wallet_address: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const { user, getMe } = useStore();

  const handleSaveRequestData = (e) => {
    e.preventDefault();

    const amount = e.target.amount.value;
    const walletAddress = e.target.walletAddress.value;
    setRequestData({
      wallet_address: walletAddress,
      amount: Number(amount),
    });
    setMode("confirm");
  };

  const handleSubmit = () => {
    if (requestData.address === "") {
      return;
    }

    setIsLoading(true);
    withdrawFundsAPI(requestData)
      .then(() => {
        enqueueSnackbar("Withdrawal was successful", { variant: "success" });
        close();
        getMe();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <WithdrawPopupView
      close={close}
      isLoading={isLoading}
      mode={mode}
      setMode={setMode}
      handleSaveRequestData={handleSaveRequestData}
      handleSubmit={handleSubmit}
      requestData={requestData}
      maxAmount={user.balance}
    />
  );
};

export default WithdrawPopupController;
