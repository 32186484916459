import { useEffect } from "react";

import { SCRIPTS } from "../const/general";

const useScript = ({ scriptType }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = SCRIPTS[scriptType];

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line
  }, []);
};

export { useScript };
