import "./index.scss";

import React from "react";

import { Loader } from "../../ui/Loader";

import mobile1 from "../../../assets/iphone1.png";
import mobile2 from "../../../assets/iphone2.png";

import { PROTECTING_STATUS } from "./const";

const HeroView = ({
  ipInfo,
  isActivating,
  protectingStatus,
  handleClickGetStarted,

  btnBoxRef,
  listRef,
  priceRef,
  animationBlockRef,
  mobile1Ref,
  mobile2Ref,
}) => (
  <section className="hero_section" id="home">
    <div className="hero_textContent">
      <div className="hero_headingBox">
        <h1>
          Explore Digital World <br />
          <span>with VPN4U</span>
        </h1>
        <div className="v-slider-frame">
          <ul className="v-slides" ref={listRef}>
            <li className="v-slide">Safely</li>
            <li className="v-slide">Easily</li>
            <li className="v-slide">Freely</li>
            <li className="v-slide">Safely</li>
          </ul>
        </div>
      </div>

      <div className="hero_ipCard" ref={priceRef}>
        <p>
          Your current IP:{" "}
          <span>
            {[PROTECTING_STATUS.loading, PROTECTING_STATUS.protected].includes(
              protectingStatus
            )
              ? "---.--.--.---"
              : ipInfo.ip}
          </span>
        </p>
        <p>
          ISP:{" "}
          <span>
            {[PROTECTING_STATUS.loading, PROTECTING_STATUS.protected].includes(
              protectingStatus
            )
              ? "-"
              : ipInfo.org}
          </span>
        </p>
        <p>
          Your status:{" "}
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            <span
              className={`hero_ipStatus ${
                protectingStatus === PROTECTING_STATUS.protected
                  ? "protected"
                  : isActivating ||
                    protectingStatus === PROTECTING_STATUS.loading
                  ? "activating"
                  : ""
              }`}
            >
              {protectingStatus === PROTECTING_STATUS.loading
                ? "Connecting"
                : protectingStatus === PROTECTING_STATUS.protected
                ? "Protected"
                : isActivating
                ? "Activating..."
                : "Unprotected"}
            </span>
            {protectingStatus !== PROTECTING_STATUS.protected &&
              isActivating && (
                <Loader
                  color="#E9EE00"
                  secondaryColor="#101010"
                  cls="hero_loader"
                />
              )}
          </span>
        </p>
      </div>

      <div className="hero_btnBox" ref={btnBoxRef}>
        <button
          className="secondary-button hero_btnBox_btn"
          onClick={handleClickGetStarted}
        >
          {protectingStatus === PROTECTING_STATUS.protected
            ? "Get started"
            : !isActivating
            ? "Get protected"
            : "Get started"}
        </button>
      </div>
    </div>
    <div className="hero_animationBlock" ref={animationBlockRef}>
      <img
        src={mobile2}
        alt="mobile2"
        ref={mobile2Ref}
        className="hero_mobile2"
      />
      <img
        src={mobile1}
        alt="mobile1"
        ref={mobile1Ref}
        className="hero_mobile1"
      />
    </div>
  </section>
);

export default HeroView;
