import { useEffect } from "react";

import { gsap } from "gsap";

import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../../utils";

const useAnim = ({
  listRef,
  priceRef,
  btnBoxRef,
  animationBlockRef,
  mobile1Ref,
  mobile2Ref,
}) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();
  const isDesktop = isDesktopMedia();

  useEffect(() => {
    const slides = listRef.current.children;
    const list = listRef.current;
    const duration = 8;
    const lineHeight = listRef.current.children[0].clientHeight;

    const tl = gsap.timeline({
      paused: true,
      repeat: -1,
    });

    const tl1 = gsap.timeline();

    Array.from(slides).forEach((slide, i) => {
      tl.to(list, duration / slides.length, {
        y: i * -1 * lineHeight,
        ease: "elastic.out(1, 1)",
      });
    });

    tl.play();

    if (isMobile) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          top: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(20),
          left: getRem(20),
          width: getRem(166),
          height: getRem(333),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(20),
            right: getRem(20),
            width: getRem(166),
            height: getRem(333),
            ease: "power2.inOut",
          },
          "<"
        );
    } else if (isTablet) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          left: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(25),
          left: getRem(18),
          width: getRem(180),
          height: getRem(360),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(25),
            right: getRem(18),
            width: getRem(180),
            height: getRem(360),
            ease: "power2.inOut",
          },
          "<"
        );
    } else if (isDesktop) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          left: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(30),
          left: getRem(25),
          width: getRem(240),
          height: getRem(480),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(30),
            right: getRem(25),
            width: getRem(240),
            height: getRem(480),
            ease: "power2.inOut",
          },
          "<"
        );
    }

    return () => {
      tl.kill();
    };
    // eslint-disable-next-line
  }, []);
};

export { useAnim };
