import React, { useRef } from "react";

import { isMobileMedia, isTabletMedia } from "../../../utils";

import { useStore } from "../../../store";

import CustomersView from "./view";

import { useAnim } from "./useAnim";

import { FEEDBACKS_DATA } from "../../../const/general";

const CustomersController = ({ handleSelectPlan }) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();

  const titleRef = useRef(null);
  const desc1Ref = useRef(null);
  const desc2Ref = useRef(null);
  const btnRef = useRef(null);
  const containerRef = useRef(null);
  const mapRef = useRef(null);

  const { ONE_YEAR_TRIAL_PLAN } = useStore();

  useAnim({
    titleRef,
    desc1Ref,
    desc2Ref,
    btnRef,
    containerRef,
    mapRef,
  });

  return (
    <>
      <CustomersView
        handleSelectPlan={handleSelectPlan}
        ONE_YEAR_TRIAL_PLAN={ONE_YEAR_TRIAL_PLAN}
        feedbacksData={FEEDBACKS_DATA}
        slidesToShow={isMobile ? 1 : isTablet ? 2.1 : 3}
        // refs
        containerRef={containerRef}
        mapRef={mapRef}
        titleRef={titleRef}
        desc1Ref={desc1Ref}
        desc2Ref={desc2Ref}
        btnRef={btnRef}
      />
    </>
  );
};

export default CustomersController;
