import { useSnackbar } from "notistack";
import { Lucid, Blockfrost } from "lucid-cardano";

import { BLOCKFROST_API, BLOCKFROST_PROJECT_ID } from "../../../const/config";

const useConnectWallet = ({ onSuccess, close }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleConnectWallet = async (wallet) => {
    const lucid = await Lucid.new(
      new Blockfrost(BLOCKFROST_API, BLOCKFROST_PROJECT_ID)
    );
    try {
      const api = await window.cardano[wallet.id].enable();
      lucid.selectWallet(api);

      localStorage.setItem("connectedWallet", wallet.id);
      close();
      onSuccess();
    } catch (e) {
      console.log(e);
      if (e === "InputsExhaustedError") {
        enqueueSnackbar("Insufficient funds", {
          variant: "error",
        });
      }
      // e?.code === 2 means user declined transaction
      else if (e?.code !== 2 && e?.code !== -3) {
        // Unknown error
        enqueueSnackbar(
          "There is an issue with the wallet, please make sure collateral is set, also consider using fresh wallet",
          { variant: "error" }
        );
      }
    }
  };

  return { handleConnectWallet };
};

export { useConnectWallet };
