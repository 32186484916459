import "./index.scss";

import React from "react";

import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";

import { BANNERS } from "./const";

const ReferralBannersView = ({ setShowLoginPopup }) => (
  <div className="referral-banners-wrapper">
    <Header setShowLoginPopup={setShowLoginPopup} />

    <div className="referral-banners">
      {Object.keys(BANNERS).map((resolution, i) => (
        <div className="referral-banners__group" key={`banner-group__${i}`}>
          <p className="referral-banners__group-lbl">Banners {resolution}</p>

          <div className={`referral-banners__box ${BANNERS[resolution].cls}`}>
            {BANNERS[resolution].items.map((banner, j) => (
              <div className="referral-banners__item" key={`banner-item__${j}`}>
                <div className="referral-banners__img-wrapper">
                  <img src={banner.src} alt="banner" loading="lazy" />
                </div>
                <p>
                  <a href={banner.downloadSrc} download>
                    Download jpg ({banner.size})
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>

    <Footer />
  </div>
);

export default ReferralBannersView;
