import ben1 from "../../../assets/benefit1.svg";
import ben2 from "../../../assets/benefit2.svg";
import ben3 from "../../../assets/benefit3.svg";

const BENEFITS_DATA = [
  {
    id: 1,
    icon: ben1,
    title: "One account for several devices",
    text: "Use one account on several devices. For example, on Android or iOS smartphones, in a Google Chrome extension, or on a Windows desktop.",
  },
  {
    id: 2,
    icon: ben2,
    title: "Wide range of servers",
    text: "Experience digital freedom and security with our high-speed servers located around the world.",
  },
  {
    id: 3,
    icon: ben3,
    title: "High data transfer rate",
    text: "Access content from anywhere in the world without geographic restrictions at speeds up to 1 GB/s.",
  },
];

export { BENEFITS_DATA };
