import React from "react";

import Header from "./Header";
import HeroSection from "./HeroSection";
import AdvantagesSection from "./Advantages";
import AboutSection from "./About";
import FeaturesSection from "./Features";
import WeOfferSection from "./WeOffer";
import PlanTable from "../ProfilePage/PlansTab/PlanTable";
import CustomersSection from "./Customers";
//import FAQSection from "./FAQ";
import Footer from "./Footer";

const HomeView = ({ isProtectedIp, setShowLoginPopup, handleSelectPlan }) => (
  <>
    <Header
      setShowLoginPopup={setShowLoginPopup}
      isHomePage
      isProtectedIp={isProtectedIp}
    />
    <HeroSection isProtectedIp={isProtectedIp} />
    <AdvantagesSection />
    <PlanTable isHomePage={true} handleSelectPlan={handleSelectPlan} />
    <AboutSection />
    <FeaturesSection />
    <WeOfferSection handleSelectPlan={handleSelectPlan} />
    <CustomersSection handleSelectPlan={handleSelectPlan} />
    {/*<FAQSection />*/}
    <Footer isHomePage isProtectedIp={isProtectedIp} />
  </>
);

export default HomeView;
